<template>
  <div>
    <correspondences :current_req="null" :correspondencesFiltered="correspondencesFiltered"></correspondences>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import correspondences from "@/components/request/Correspondence";

export default {
  name: "Court",
  components: {
    correspondences
  },

  data() {
    return {
      correspondenceFilteres:{},
    };
  },

  async created() {
    Object.assign(this.correspondenceFilteres, this.storeCorrespondenceFilters);
    this.filterCorrespondences(this.correspondenceFilteres);
    this.clearCorrespondencesFilter();
    await this.retrieveCorrespondences();
    await this.retriveRequests();
    await this.retriveTemplate();
  },

  computed: {
    ...mapGetters('correspondence', {
      'correspondencesFiltered':'correspondencesFiltered'
    }),
     ...mapState('correspondence', {
      'storeCorrespondenceFilters':'filter'
    }),
    ...mapState("template", {
      template: "templates"
    }),
  },
  watch: {
    correspondencesFiltered: {
      handler(){
        Object.assign(this.correspondenceFilteres, this.storeCorrespondenceFilters);
      }, deep: true 
    }
  },
  methods: {
    ...mapActions('correspondence',['retrieveCorrespondences','filterCorrespondences','clearCorrespondencesFilter']),
    ...mapActions("request", ["setRequestItem", "retriveRequestItem","retriveRequests"]),
    ...mapActions('template',['retriveTemplate']),

  }

};
</script>